body {
  color: #fff;
  background-image: url("bg.9551e5d1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Product Sans, Helvetica, Arial, sans-serif;
  font-size: 100%;
  font-weight: 300;
  overflow: hidden;
}

.main {
  text-align: center;
  position: relative;
  top: 45%;
  transform: translateY(25%);
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

footer > a > img {
  float: right;
  width: 30px;
  position: relative;
  bottom: 5px;
  right: 5px;
}

.avatarContainer {
  filter: drop-shadow(10px 10px 20px #000);
  border-radius: 100px;
  width: max-content;
  height: max-content;
  margin: 0 auto;
}

.avatar {
  pointer-events: all;
  filter: none;
  border-radius: 100px;
  width: 175px;
  height: 175px;
}

.topping > .container-top {
  min-width: 50%;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.summary {
  min-height: 75vh;
}

.summary > h1 {
  font-size: 650%;
  font-weight: 500;
}

h3 {
  font-size: 250%;
}

h1 {
  filter: drop-shadow(10px 10px 20px #000);
  margin: 0;
  font-size: 650%;
}

.list-group {
  text-align: center;
}

ul {
  padding: 0;
}

ul li {
  filter: drop-shadow(10px 10px 20px #000);
  margin-left: 25px;
  margin-right: 0;
  list-style-type: none;
  display: inline-block;
}

ul li:first-of-type {
  padding-left: 0;
}

ul li:first-child {
  margin: 0;
}

ul li img {
  opacity: 1;
  width: 120px;
  transition: all .4s ease-in-out;
}

ul li img:hover {
  transform: scale(1.025);
}
/*# sourceMappingURL=index.cad83319.css.map */
