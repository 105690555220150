body
  overflow hidden
  margin 0
  padding 0
  background-image url('../img/site/bg.png')
  height 100%
  background-position center
  background-size cover
  background-repeat no-repeat
  color #fff
  font-weight 300
  font-size 100%
  font-family 'Product Sans', Helvetica, Arial, sans-serif

.main
  position relative
  top 45%
  text-align center
  transform translateY(25%)

footer
  position fixed
  bottom 0
  width 100%

  >a>img
    width 30px
    float right
    position relative
    right 5px
    bottom 5px

.avatarContainer
  width max-content
  height max-content
  margin 0 auto
  border-radius 100px
  filter drop-shadow(10px 10px 20px black)

.avatar
  width 175px
  height 175px
  border-radius 100px
  pointer-events all
  filter none

.topping
  & > .container-top
    margin-right auto
    margin-left auto
    min-width 50%
    max-width 75%

.summary
  min-height 75vh

  & > h1
    font-weight 500
    font-size 650%

h3
  font-size 250%

h1
  margin 0
  font-size 650%
  filter drop-shadow(10px 10px 20px black)

.list-group
  text-align center

ul
  padding 0

  li
    display inline-block
    margin-right 0
    margin-left 25px
    list-style-type none
    filter drop-shadow(10px 10px 20px black)

    &:first-of-type
      padding-left 0

    &:first-child
      margin 0

    img
      width 120px
      opacity 1
      transition all 0.4s ease-in-out

      &:hover
        transform scale(1.025)
